import React from 'react';
import Modal from './modal';
import PubSub from 'pubsub-js';

export default () => {
  const showModal = () => {
    PubSub.publish(
      'Message.New',
      {
        inbox: { type: 'User', id: window.PageData.schoolhire.id },
        interlocutor: { type: 'Venue' }
      }
    )
  };

  return (
    <div className="vert-offset-bottom-15 vert-offset-top-15">
      <Modal path="admin/messages" name="Message" title="Message"/>
      <button className="btn btn-block btn-primary btn-no-radius btn-lg" onClick={showModal}>
        New message
      </button>
    </div>
  );
}
