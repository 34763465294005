import React from 'react';
import Message from './message';
import Form from './form';
import { Can } from '../ability_context';
import Paginate from "../admin/reports/paginate";


export default ({ data, included, inbox, interlocutor, onSendMessage, path, page, pagesCount, onChangePage }) => {
  if (!(Array.isArray(data) && data.length > 0)) return null;

  const isSchoolhireInterlocutor = (interlocutor || {}).schoolhire;

  const PagePaginate = () => {
    return (
      <Paginate
        className={'messages-pagination'}
        page={page}
        pageCount={pagesCount}
        onPageClick={onChangePage}
      />
    )
  }

  return(
    <div id="messages">
      <div id="send_message_form">
        <div className="row">
          <div className="col-md-12 vert-offset-bottom-10 text-center" style={{ backgroundColor: '#f2f5fc' }}>
            <h4>{inbox.name} &rarr; {interlocutor.name}</h4>
          </div>
        </div>
        {!isSchoolhireInterlocutor &&
        <>
          <Can I="newMessageForm" a="AdminMessages">
            <Form inbox={inbox}
                  included={included}
                  interlocutor={interlocutor}
                  onSend={onSendMessage}
                  path={path}/>
          </Can>
          <Can I="newMessageForm" a="AccountMessages">
            <Form inbox={inbox}
                  included={included}
                  interlocutor={interlocutor}
                  onSend={onSendMessage}
                  path={path}/>
          </Can>
        </>
        }
      </div>

      <PagePaginate/>
      {data.map((item, index) => <Message key={index} inbox={inbox} data={item}/>)}
      <PagePaginate/>

    </div>
  )
}
