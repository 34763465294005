import React from 'react';
import ReactPaginate from 'react-paginate';

export default ({ onPageClick, page, pageCount, className = 'report-pagination' }) => {
  const handlePageClick = (page) => {
    if (onPageClick) onPageClick(page.selected + 1);
  };

  if (!Number.isInteger(pageCount)) return null;
  if (pageCount < 2) return null;

  return (
    <div className={className}>
      <ReactPaginate
        initialPage={page-1}
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        disableInitialCallback={true}
      />
    </div>
  );
}
