import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import MainMenu from './menu';
import SubMenu from './sub_menu';
import ErrorBoundary from '../error_boundary';
import ReduxToastr from 'react-redux-toastr';
//import * as Sentry from '@sentry/browser';
import Contexts from '../contexts';

export default class Application extends Component {
  render() {
    let { menu, submenu, children, showFooter, containerClasses } = this.props;

    showFooter = (showFooter === undefined) ?  true : showFooter;

    const toastrBlock = (
      <ReduxToastr
        timeOut={5000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
    );

    //Sentry.init({ dsn: document.getElementsByTagName('body')[0].dataset.sentryDsn });

    containerClasses = containerClasses || 'container container-white';

    return (
      <Contexts>
        <div id="page-wrapper">
          {toastrBlock}
          <Header/>
          <div id="main">
            {menu && <MainMenu>{menu}</MainMenu>}
            {submenu && <SubMenu>{submenu}</SubMenu>}

            <div className={containerClasses}>
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            </div>
          </div>
          {showFooter && <Footer/>}
        </div>
      </Contexts>
    );
  }
}
