import React from 'react';
import { Button } from 'react-bootstrap';
import SmartModal from '../../../smart/modal';
import Formatted from '../../../formatted';

export default class Modal extends SmartModal {
  render() {
    const {
      internal_reference,
      formatted_address,
      event_date,
      time_slot,
      title,
      booked_places,
      schedule_item_places
    } = this.props;

    return (
      <dl className="dl-horizontal">
        <dt>Activity:</dt>
        <dd>{internal_reference}</dd>

        <dt>Address:</dt>
        <dd>{formatted_address}</dd>

        <dt>Date:</dt>
        <dd><Formatted.Date date={event_date}/></dd>

        <dt>Time slot:</dt>
        <dd>{time_slot}</dd>

        <dt>Booking option:</dt>
        <dd>{title}</dd>

        <dt>Quantity of spaces:</dt>
        <dd>{booked_places} out of {schedule_item_places}</dd>
      </dl>
    );
  }
}
