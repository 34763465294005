import React, { Component } from 'react';
import FieldGroup from '../smart/field_group';
import axios from 'axios';
import SpinnerModal from "../spinner_modal";

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: '',
      attachment: '',
      formErrors: [],
      showSpinner: false
    };

    this.formRef = React.createRef();
  }

  sendMessage = (e) => {
    e.preventDefault();
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    const { inbox, interlocutor, onSend, path } = this.props;
    let data = new FormData(this.formRef.current);

    data.append('inbox_type', inbox.type);
    data.append('inbox_id', inbox.id);
    data.append('interlocutor_type', interlocutor.type);
    data.append('interlocutor_id', interlocutor.id);

    this.setState({ showSpinner: true, formErrors: [] })

    axios
      .post(path, data, config)
      .then((response) => {
        this.setState({ key: Math.random() });
        onSend()
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      })
      .finally(() => {
        this.setState({ showSpinner: false })
      });
  };

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === undefined) return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  render() {
    const { body, attachment, key, showSpinner } = this.state;

    return (
      <div>
        <form noValidate="novalidate" ref={this.formRef} key={key}>
          <div className="row">
            <div className="col-lg-10 message-text-input">
              <FieldGroup
                type="textarea"
                rows={3}
                name="message[body]"
                error={this.formError("body")}
              />
              {/*<div className="alert alert-warning">*/}
                {/*This thread is for outgoing system messages only. Please ensure you message the Venue directly.*/}
              {/*</div>*/}
            </div>
            <div className="col-lg-2">
              <a className="btn btn-primary btn-lg btn-no-radius" id="conversation_send_message" onClick={this.sendMessage}>
                Send
              </a>

              <SpinnerModal show={showSpinner}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FieldGroup
                label="Attachment:"
                type="file"
                name="message[attachment_attributes][attachment]"
                error={this.formError("attachment")}
              />
            </div>
          </div>
          <div className="clearfix" />
        </form>
        <div style={{ borderBottom: '1px solid #3BA6FC', margin: '0px -15px 15px -15px' }} />
      </div>
    );
  }
}