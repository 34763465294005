import React, { Fragment } from 'react';
import SmartModal from '../smart/modal';
import FieldGroup from '../smart/field_group';
import { Button } from 'react-bootstrap';
import SpinnerModal from "../spinner_modal";
import ModalMessages from './modal_messages';
import axios from "axios";
import PubSub from "pubsub-js";

export default class Modal extends SmartModal {
  constructor(props) {
    super(props);

    this.state = {
      modalStyle: undefined,
      show: false,
      sendDisabled: false,
      data: {},
      included: {},
      formData: {},
      formErrors: {},
      loading: true,
      showSpinner: false
    };

    this.formRef = React.createRef();
  }

  onShowNew = () => {
    this.reloadMessages();
  }

  getInbox() {
    return this.state.data.inbox;
  }

  getInterlocutor() {
    return this.state.data.interlocutor;
  }

  showNew = () => {
    const { path } = this.props;

    const inbox = this.getInbox();
    const interlocutor = this.getInterlocutor();

    let querySting = $.param({
      inbox_type: inbox.type,
      inbox_id: inbox.id,
      interlocutor_type: interlocutor.type,
      interlocutor_id: interlocutor.id,
    });

    axios
      .get(`${path}/new.json?${querySting}`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowNew()
        );
      })
      .catch((error) => {
        console.log(error)
      });
  };

  applyNew = () => {
    const { path, name } = this.props;
    let data = this.getData();
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    this.setState({ showSpinner: true, sendDisabled: true, formErrors: {} })

    axios
      .post(path, data, config)
      .then((response) => {
        PubSub.publish(`${name}.Created`, {});
        this.formRef.current.reset();
        this.reloadMessages();
        this.setState({ sendDisabled: false });
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors, sendDisabled: false });
      })
      .finally(() => {
        this.setState({ showSpinner: false })
      });
  };

  reloadMessages = () => {
    const inbox = this.getInbox();
    const interlocutor = this.getInterlocutor();

    let querySting = $.param({
      inbox_type: inbox.type,
      inbox_id: inbox.id,
      interlocutor_type: interlocutor.type,
      interlocutor_id: interlocutor.id,
    });

    axios
      .get(`${this.props.path}.json?${querySting}`)
      .then(response => {
        let data = Object.assign({}, this.state.data, { messages: response.data.data });
        this.setState({ data: data });
      })
  }

  renderInterlocutorFields() {
    const interlocutor = this.getInterlocutor();

    let disabled = false;
    let options = [];
    let hideDropDown = false;

    if (interlocutor.id) {
      disabled = true;
      options=[{ id: interlocutor.id, title: interlocutor.name }];
      if (!interlocutor.name) hideDropDown = true;
    } else {
      options = this.includedData('venues');
    }

    return (
      <Fragment>
        <input type="hidden" name="interlocutor_id" value={interlocutor.id}/>

        {!hideDropDown &&
        <FieldGroup
          label="To:"
          type="select"
          disabled={disabled}
          options={options}
          required={true}
          name="interlocutor_id"
        />
        }

        <input type="hidden" name="interlocutor_type" value={interlocutor.type}/>
      </Fragment>
    );
  }

  getTitle = () => {
    const { included }  = this.state;
    const { inbox, interlocutor  } = included;

    if (inbox && interlocutor) return `${inbox.title} => ${interlocutor.title}`;
    
    return "Messages";
  }

  renderForm() {
    const inbox = this.getInbox();
    const interlocutor = this.getInterlocutor();
    const { showSpinner } = this.state;

    return (
      <Fragment>
        <div>
          <input type="hidden" name="inbox_type" value={inbox.type}/>
          <input type="hidden" name="inbox_id" value={inbox.id}/>

          {this.renderInterlocutorFields()}

          <FieldGroup
            label="Message:"
            type="textarea"
            required={true}
            name="message[body]"
            rows={5}
            error={this.formError("body")}
          />

          <FieldGroup
            label="Attachment:"
            type="file"
            name="message[attachment_attributes][attachment]"
            error={this.formError("attachment")}
          />
        </div>

        <div className='pull-right'>
          <Button
            disabled={this.state.sendDisabled}
            className='btn btn-primary'
            onClick={this.handlePerform}>
              Send
          </Button>
          <SpinnerModal show={showSpinner}/>
        </div>

        <div className='clearfix'/>

        <ModalMessages
          inbox={inbox}
          interlocutor={interlocutor}
          messages={this.state.data.messages}
        />
      </Fragment>
    )
  }

  renderButtons() {
    return null;
  }
}
